const enText = {
  search: "Search",
  searchLabel: "What are you loking for?",
  lSelect: "Language Select",
  login: "Login",
  ulinks: "Useful links",
  termscontitios: "Terms & Conditions",
  privpolicy: "Privacity Policy",
  learnmore: "Learn more",
  getInvolved: "Get Involved",
  involvedText:
    "Please tell us your story or share your experiences in peace building and help us create a shared understanding of humanity through peace and values education.",
  stayInTouch: "STAY IN TOUCH",
  stayInTouchText:
    "Sign up for our news letter for all of the lastest information.",
  joinNewsletter: "JOIN OUR NEWSLETTER",
  userFeedbackForm: "USERS FEEDBACK FORM",
  userFeedbackFormText:
    "We would really like to know your experience on this platform.",
  feedBackForm: "FEEDBACK FORM",
  teacherActivities: "TEACHER ACTIVITIES",
  standardAddreses: "STANDARDS ADDRESSED",
  learnerActivities: "LEARNER ACTIVITIES",
  competiences: "COMPETENCIES",
  crossCuttingIssues: "CROSS CUTTING ISSUES",
  shareThis: "Share this",
  downloadThisArticle: "Download this article",
  titleOfTheLesson: "TITLE OF THE LESSON",
  instructionalObjective: "INSTRUCTIONAL OBJECTIVE",
  planForThisClass: "PLAN FOR THIS CLASS",
  materials: "MATERIALS",
  references: "References",
  originalFileName: "Original file name",
  size: "Size",
  mimeType: "Mime type",
  author: "AUTHOR",
  format: "FORMAT",
  typeOfMedia: "TYPE OF MEDIA",
  interviewees: "Interviewees",
  interviewer: "Interviewer",
  language: "LANGUAGE",
  targetAudiences: "TARGET AUDIENCES",
  quizz: "Quizz",
  quizzes: "Quizzes",
  description: "Description",
  questions: "Questions",
  selectStartEndTime: "Select a start and end time to create a video segment",
  pageNotFoundTitle: "Page Not Found",
  pageNotFoundText: "Sorry, the page you are looking for cannot be found.",
  pageNotFoundText2: "Please go back to the homepage",
  peaceClub: "Peace club",
};

export default enText;
